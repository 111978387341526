<template>
	<div class="homePage">
		<!-- 轮播图 -->
		<el-carousel height="450px" :interval="5000">
		    <el-carousel-item v-for="(item,index) in swiperList" :key="index" :style="{ backgroundImage: 'url('+item+')' }">
				<!-- <img :src="item" /> -->
		    </el-carousel-item>
		</el-carousel>
		<!-- 登录框 -->
		<div class="UserLogin_box" v-if="!isMian">
			<div class="afterLogin" v-if="isLogin">
				<div class="studentCon">
					<img v-if="userinfo.stu_headimg" :src="userinfo.stu_headimg" alt="" class="studentImg" />
					<span v-else>
						<img v-if="userinfo.stu_sex == '女'" src="@/views/images/nv.png" alt="" class="studentImg" />
						<img v-else src="@/views/images/nan.png" alt="" class="studentImg" />
					</span>
					<div class="studentInfo">
						<span>欢迎您</span>
						<span class="studentName">{{userinfo.stu_name}}</span>
					</div>
					<div class="websiteName">{{cityName}}专业技术人员继续教育平台</div>
					<div class="loginBtn" @click="toCourseList">去报名</div>
					<div class="loginBtn toLCenter" @click="toLCenter">进入学习中心</div>
				</div>
			</div>
			<div class="UserLogin" v-else>
				<div class="loginText">用户登录</div>
				<div class="loginCon">
					<div class="inputDiv">
						<img src="@/views/images/account_icon.png" />
						<input type="text" v-model="account" placeholder="请输入身份证号/手机号" maxlength="18" @blur="blurAccount" />
						<span class="error" v-if="isAccount">请输入身份证号/手机号</span>
					</div>
					<div class="inputDiv">
						<img src="@/views/images/pwd_icon.png" />
						<input type="password" v-model="pwd" placeholder="请输入密码" minlength="6" maxlength="18" show-password @blur="blurPassword" />
						<span class="error" v-if="isPwd">请输入密码</span>
					</div>
					<div class="verifyDiv">
					  <drag-verify
					    ref="dragVerify"
						:width="224"
						:height="30"
						background="#fff"
					    :isPassing.sync="isPassing"
					    text="按住滑块，拖动到最右边"
					    successText="验证通过"
					    handlerIcon="el-icon-d-arrow-right"
					    successIcon="el-icon-circle-check"
						textColor="#999"
					  >
					  </drag-verify>
					  <span class="error" v-if="isDrag">请拖动滑块</span>
					</div>
					<div class="isRemember">
						<el-checkbox-group v-model="isRemember">
						    <el-checkbox label="记住密码"></el-checkbox>
						</el-checkbox-group>
					</div>
					<div class="loginBtn" @click="LoginFun">登录</div>
					<div class="registerAndForget">
						<span class="register"><router-link to='/register'>注册</router-link></span>
						<span class="Forget"><router-link to='/findPassword'>忘记密码</router-link></span>
					</div>
				</div>
			</div>
		</div>
		<!-- 内容 -->
		<div class="pageCon">
			<div class="pageOne">
				<div class="pageOne_left pageOneItem">
					<el-tabs v-model="activeName">
					    <el-tab-pane label="通知公告" name="first">
							<div class="newsItem">
								<div class="newsItem_left" v-for="(item, index) in noticeList" :key="index" v-if="index < 1">
									<img v-if="item.inf_thumb" :src="item.inf_thumb" class="noticeOneImg" />
									<img v-else src="@/views/images/notice.png" class="noticeOneImg" />
									<div class="noticeOneTitle">{{item.inf_title}}</div>
									<div class="noticeOneDes">
										{{item.inf_summary.slice(0,26)}}...
										<span class="viewDetails" @click="gotoDetails(item,1)">查看详情<i class="el-icon-arrow-right"></i></span>
									</div>
								</div>
								<div class="newsItem_right">
									<div class="newsList">
										<div class="newsLi" v-for="(item, index) in noticeList" :key="index" v-if="index > 0" @click="gotoDetails(item,1)">
											{{item.inf_title}}
											<span>{{item.updatetime.substring(5, 10)}}</span>
										</div>
									</div>
								</div>
							</div>
						</el-tab-pane>
					    <el-tab-pane label="政策法规" name="second">
							<div class="newsItem">
								<div class="newsItem_left" v-for="(item, index) in policyList" :key="index" v-if="index < 1">
									<img v-if="item.inf_thumb" :src="item.inf_thumb" class="noticeOneImg" />
									<img v-else src="@/views/images/policy.png" class="noticeOneImg" />
									<div class="noticeOneTitle">{{item.inf_title}}</div>
									<div class="noticeOneDes">
										{{item.inf_summary.slice(0,26)}}...
										<span class="viewDetails" @click="gotoDetails(item,2)">查看详情<i class="el-icon-arrow-right"></i></span>
									</div>
								</div>
								<div class="newsItem_right">
									<div class="newsList">
										<div class="newsLi" v-for="(item, index) in policyList" :key="index" v-if="index > 0"  @click="gotoDetails(item,2)">
											{{item.inf_title}}
											<span>{{item.updatetime.substring(5, 10)}}</span>
										</div>
									</div>
								</div>
							</div>
						</el-tab-pane>
					</el-tabs>
					<div class="moreNews" @click="moreNews">
						<span>更多</span>
						<i class="el-icon-arrow-right"></i>
					</div>
				</div>
				<div class="pageOne_right pageOneItem">
					<div class="learningProcess">
						<div class="publicTitle">在线学习流程</div>
						<div class="learning_box">
							<div class="processItem">
								<span>01</span>
								注册登录
								<i class="el-icon-bottom"></i>
							</div>
							<div class="processItem">
								<span>02</span>
								购买课程
								<i class="el-icon-bottom"></i>
							</div>
							<div class="processItem">
								<span>03</span>
								在线学习
								<i class="el-icon-bottom"></i>
							</div>
							<div class="processItem">
								<span>04</span>
								在线考试
								<i class="el-icon-bottom"></i>
							</div>
							<div class="processItem">
								<span>05</span>
								打印证书
							</div>
						</div>
					</div>
				</div>
			</div>
			<!-- 集体报名 -->
			<img src="@/views/images/groupRegistration.png" v-if="!isMian" class="groupRegistration" @click="togroup" />
			<!-- 专题培训 -->
			<div class="excellentCourses">
				<div class="publicTitle">
					专题培训
					<div class="moreCourses" @click="tospecialList">
						<span>更多</span>
						<i class="el-icon-arrow-right"></i>
					</div>
				</div>
				<div class="specialList">
					<div class="specialItem" v-for="(item,index) in specialList" :key="index">
						<div class="specialbg"></div>
						<img class="specialImg" :src="item.zt_thumb" @click="getspecialDe(item)"/>
						<div class="specialTit">{{item.zt_name}}</div>
						<div class="specialCourses">
							<span>共{{item.zt_count}}门课</span>
							<span>{{item.zt_sumtime}}学时</span>
						</div>
					</div>
					<!-- <div class="clearFix"></div> -->
				</div>
			</div>
			<!-- 精品课程 -->
			<div class="excellentCourses">
				<div class="publicTitle">
					精品课程
					<div class="moreCourses" @click="tocourseList">
						<span>更多</span>
						<i class="el-icon-arrow-right"></i>
					</div>
				</div>
				<div class="coursesList">
					<div class="coursesItem" v-for="(item,index) in courses" :key="index">
						<img class="coursesImg" :src="item.cou_thumb" @click="getCoursesDe(item)"/>
						<div class="coursesTit">{{item.cou_name}}</div>
						<div class="coursesYear">{{item.course_type.dic_name}}</div>
						<div class="coursesDetails" @click="getCoursesDe(item)">查看详情</div>
					</div>
					<div class="clearFix"></div>
				</div>
			</div>
			<!-- 友情链接 -->
			<div class="Links">
				<div class="publicTitle">友情链接</div>
				<div class="linkList">
					<a v-for="(item,index) in linkList" :key="index" :href="item.link_href" target="_blank" class="linkImg_a">
						<img :src="item.link_pic" class="linkImg" />
					</a>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import Bus from '/src/views/js/bus.js'
	import dragVerify from 'vue-drag-verify2'
	export default {
		components:{
		    dragVerify
		},
		watch:{
			isPassing(val){
				if(val){
					this.isDrag = false;
				}
			}
		},
		data() {
			return {
				provinceswiperList:[require("./images/banner1.png"),require("./images/banner2.png")],//省轮播图数据
				swiperList:[require("./images/banner1.png"),require("./images/banner2.png")],//轮播图
				isMian:true,//是否为省平台
				mySwiper:null,//轮播图
				account:"",//账号
				pwd:"",//密码
				isPassing:false,//验证状态
				isRemember:[],//是否记住密码
				isLogin:false,//是否登录
				activeName:"first",//新闻切换
				linkList:[],//友情链接
				courses:[],//课程列表
				are_code:"",//选择的市
				noticeList:[],//通知公告列表
				policyList:[],//政策法规列表
				isAccount:false,//账号验证
				isPwd:false,//密码验证
				isDrag:false,//滑块验证
				cityName:"",//城市名
				userinfo:{},//用户信息
				specialList:[],//专题列表
			}
		},
		methods: {
			//通知公告、政策法规列表
			getNoticeAndPolicy(inf_type){
				this.$http.post(this.PublicJs.publicPath + "/api/website.index/information.html", {
					are_code:this.are_code,
					inf_type:inf_type,
					page:1,
					limit:7,
					inf_istop:""
				}, {
					emulateJSON: true
				}).then(response => {
					if(response.data.code == 1){
						if(inf_type == 1){
							this.noticeList = response.data.data.data;
						}else{
							this.policyList = response.data.data.data;
						}
					}else{
						this.$message({
							message: response.data.msg,
							type: 'warning',
							showClose: true
						});
					}
				}, response => {
					console.log(response)
				});
			},
			//专题列表
			getspecialList(){
				this.$http.post(this.PublicJs.publicPath + "/api/website/Zt/getHome", {
					emulateJSON: true
				}).then(response => {
					if(response.data.code == 1){
						this.specialList = response.data.data.data;
					}else{
						this.$message({
							message: response.data.msg,
							type: 'warning',
							showClose: true
						});
					}
				}, response => {
					console.log(response)
				});
			},
			//课程
			getCourses(){
				this.$http.post(this.PublicJs.publicPath + "/api/website.index/course.html", {
					limit:5,
					page:1,
					are_code:this.are_code
				}, {
					emulateJSON: true
				}).then(response => {
					if(response.data.code == 1){
						this.courses = response.data.data.data;
					}else{
						this.$message({
							message: response.data.msg,
							type: 'warning',
							showClose: true
						});
					}
				}, response => {
					console.log(response)
				});
			},
			//友情链接
			getLink(){
				this.$http.post(this.PublicJs.publicPath + "/api/website.index/link.html", {
					limit:5,
					page:1
				}, {
					emulateJSON: true
				}).then(response => {
					if(response.data.code == 1){
						this.linkList = response.data.data.data;
					}else{
						this.$message({
							message: response.data.msg,
							type: 'warning',
							showClose: true
						});
					}
				}, response => {
					console.log(response)
				});
			},
			//获取市平台轮播图
			getBanner(){
				this.$http.post(this.PublicJs.publicPath + "/api/website.index/banner.html", {
					are_code:this.are_code,
					page:1,
					limit:5
				}, {
					emulateJSON: true
				}).then(response => {
					if(response.data.code == 1){
						var bannerList = response.data.data.data;
						this.swiperList = [];
						for(var i =0;i<bannerList.length;i++){
							this.swiperList.push(bannerList[i].ban_pic)
						}
					}else{
						this.$message({
							message: response.data.msg,
							type: 'warning',
							showClose: true
						});
					}
				}, response => {
					console.log(response)
				});
			},
			//学员登录
			LoginFun(){
				//账号失去焦点
				this.blurAccount();
				//密码失去焦点
				this.blurPassword();
				//滑块验证
				if(!this.isPassing){
					this.isDrag = true;
				}
				if(this.account && this.pwd && this.isPassing){
					this.$http.post(this.PublicJs.publicPath + "/api/student.index/login.html", {
						account:this.PublicJs.Encrypt(this.account.toUpperCase()),
						password:this.PublicJs.Encrypt(this.pwd),
						are_code:this.are_code
					}, {
						emulateJSON: true
					}).then(response => {
						if(response.data.code == 1){
							if(this.isRemember){
								this.$cookies.set("account",this.PublicJs.Encrypt(this.account),"14d");
								this.$cookies.set("password",this.PublicJs.Encrypt(this.pwd),"14d");
							}else{
								this.$cookies.remove("account");
								this.$cookies.remove("password");
							}
							window.sessionStorage.setItem('user_id',this.PublicJs.Encrypt(response.data.data.userinfo.user_id))
							window.sessionStorage.setItem('token',response.data.data.userinfo.token)
							window.sessionStorage.setItem('userinfo',this.PublicJs.Encrypt(JSON.stringify(response.data.data.userinfo)))
							this.$router.push({
								path: "/LearningCenter"
							});
						}else if(response.data.code == -1){
							this.$message({
								message: response.data.msg,
								type: 'warning',
								showClose: true
							});
							setTimeout(function(){
								window.location.href = response.data.data.data.domain + "/web";
							},2000);
						}else{
							this.pwd = "";
							this.$message({
								message: response.data.msg,
								type: 'warning',
								showClose: true
							});
						}
					}, response => {
						console.log(response)
					});
				}
			},
			//账号失去焦点
			blurAccount(){
				if(!this.account){
					this.isAccount = true;
				}else{
					this.isAccount = false;
				}
			},
			//密码失去焦点
			blurPassword(){
				if(!this.pwd){
					this.isPwd = true;
				}else{
					this.isPwd = false;
				}
			},
			//查看更多新闻
			moreNews(){
				if(this.activeName == "first"){
					this.$router.push({
						path: "/newsList",
						query: {
							col_id: this.PublicJs.Encrypt(1),
							col_name:this.PublicJs.Encrypt("通知公告"),
						}
					});
					window.sessionStorage.setItem('menuChose',this.PublicJs.Encrypt(1))
					Bus.$emit('busmenuChose', 1);
				}else{
					this.$router.push({
						path: "/newsList",
						query: {
							col_id: this.PublicJs.Encrypt(2),
							col_name:this.PublicJs.Encrypt("政策法规"),
						}
					});
					window.sessionStorage.setItem('menuChose',this.PublicJs.Encrypt(2))
					Bus.$emit('busmenuChose', 2);
				}
				window.sessionStorage.setItem('isHome',false)
				Bus.$emit('busIsHome', false);//是否为省平台
			},
			//跳转专题列表
			tospecialList(){
				this.$router.push({
					path: "/specialList"
				});
				Bus.$emit('busIsHome', false);//是否为省平台
				window.sessionStorage.setItem('isHome',false)
				window.sessionStorage.setItem('menuChose',this.PublicJs.Encrypt("special"))
			},
			//跳转课程列表
			tocourseList(){
				this.$router.push({
					path: "/WcourseList"
				});
				Bus.$emit('busIsHome', false);//是否为省平台
				window.sessionStorage.setItem('isHome',false)
			},
			//跳转详情
			getCoursesDe(item){
				this.$router.push({
					path: "/courseDetails",
					query: {
						ids:this.PublicJs.Encrypt(item.cou_id)
					}
				});
				Bus.$emit('busIsHome', false);//是否为省平台
				window.sessionStorage.setItem('isHome',false)
			},
			//跳转专题详情
			getspecialDe(item){
				this.$router.push({
					path: "/specialInfo",
					query: {
						zt_id:this.PublicJs.Encrypt(item.zt_id)
					}
				});
				Bus.$emit('busIsHome', false);//是否为省平台
				window.sessionStorage.setItem('isHome',false)
				window.sessionStorage.setItem('menuChose',this.PublicJs.Encrypt("special"))
			},
			//跳转学习中心
			toLCenter(){
				this.$router.push({
					path: "/LearningCenter"
				});
			},
			//跳转课程列表
			toCourseList(){
				Bus.$emit('busIsHome', false);//是否为省平台
				window.sessionStorage.setItem('isHome',false)
				window.sessionStorage.setItem('menuChose',this.PublicJs.Encrypt("course"))
				this.$router.push({
					path: "/courseList"
				});
			},
			//查看详情
			gotoDetails(item,type){
				this.$router.push({
					path: "/newsDetails",
					query: {
						col_name:this.PublicJs.Encrypt("通知公告"),
						ids:this.PublicJs.Encrypt(item.inf_id)
					}
				});
				window.sessionStorage.setItem('isHome',false)
				window.sessionStorage.setItem('menuChose',this.PublicJs.Encrypt(type))
				Bus.$emit('busmenuChose', type);//当前选中
				Bus.$emit('busIsHome', false);//是否为首页
			},
			//跳转集体报名
			togroup(){
				// console.log(1)
				this.$router.push({
					path: "/GroupRegistration"
				});
			}
		},
		mounted () {
			
		},
		created(){
			this.isMian = false;
			this.are_code = this.$cookies.get("are_code")
			//获取市轮播图
			this.getBanner();
			this.cityName = this.$cookies.get("cityName")
			
			//友情链接
			this.getLink();
			//专题列表
			this.getspecialList();
			//精品课程
			this.getCourses();
			//通知公告
			this.getNoticeAndPolicy(1)
			//政策法规列表
			this.getNoticeAndPolicy(2);
			if(this.$cookies.get("account")){
				this.account = this.PublicJs.Decrypt(this.$cookies.get("account"))
				this.isRemember = true;
			}
			if(this.$cookies.get("password")){
				this.pwd = this.PublicJs.Decrypt(this.$cookies.get("password"))
			}
			if(window.sessionStorage.getItem('userinfo')){
				this.isLogin = true;
				this.userinfo = JSON.parse(this.PublicJs.Decrypt(window.sessionStorage.getItem('userinfo')))
			}
		}
	}
</script>

<style scoped="scoped">
	.homePage{
		position: relative;
		padding-bottom: 60px;
	}
	/* .tips{
		position: fixed;
		left: 0;
		top: 200px;
		background-color: #fff;
		border-radius: 8px;
		box-shadow: 1px 1px 6px rgba(153,153,153,0.18);
		width: 280px;
		padding: 15px;
		z-index: 999;
	}
	.tipsTwo{
		top: 433px;
	}
	.tips p{
		margin-bottom: 10px;
		font-size: 14px;
		font-weight: bold;
		text-indent: 2em;
		word-break: break-all;
	} */
	.el-carousel__item{
		background-repeat: no-repeat;
		background-size: 1920px 450px;
		background-position: center center;
	}
	/* 用户登录 */
	.UserLogin_box{
		position: absolute;
		width: 1200px;
		top: 60px;
		left: 50%;
		margin-left: -600px;
	}
	.UserLogin,
	.afterLogin{
		width: 278px;
		height: 327px;
		background-color: #fff;
		z-index: 9;
		position: absolute;
		right: 0;
		top: 0;
		/* border: 2px solid rgba(255,255,255,0.5); */
		border-radius: 6px;
	}
	.afterLogin{
		border-color: #FFF;
	}
	.loginText{
		height: 46px;
		line-height: 46px;
		text-align: center;
		color: #333;
		border-bottom: 1px solid #EEEEEE;
		font-size: 14px;
		font-weight: 700;
	}
	.loginCon{
		width: 226px;
		margin: 0 auto;
	}
	.inputDiv{
		width: 100%;
		height: 30px;
		background-color: #fff;
		border: 1px solid #dce3e7;
		border-radius: 6px;
		margin-top: 16px;
		line-height: 30px;
		font-size: 0;
		padding: 0 8px;
		position: relative;
	}
	.inputDiv img{
		vertical-align: middle;
		margin-right: 13px;
	}
	.inputDiv input{
		vertical-align: top;
		width: 173px;
		height: 28px;
		border: none;
		background-color: transparent;
		outline: none;
		color: #999;
		font-size: 12px;
	}
	.inputDiv input::-webkit-input-placeholder { 
		color: #999; 
	} 
	.inputDiv input:-moz-placeholder { 
		color: #999; 
	} 
	.inputDiv input::-moz-placeholder { 
		color: #999; 
	} 
	.inputDiv input:-ms-input-placeholder { 
		color: #999; 
	}
	.error{
		position: absolute;
		font-size: 12px;
		bottom: -14px;
		left: 39px;
		line-height: 12px;
		color: #fb2e00;
	}
	.verifyDiv{
		border: 1px solid #dce3e7;
		margin-top: 16px;
		border-radius: 6px;
		position: relative;
		background-color: #fff;
	}
	.isRemember{
		margin: 18px 0;
		padding-left: 4px;
	}
	.loginBtn{
		width: 100%;
		height: 30px;
		/* background-color: #4c6df1; */ /* 不支持线性的时候显示 */
		background-image: linear-gradient(0deg,#FF4936 0%, rgba(255,73,54,0.90) 100%);
		border-radius: 15px;
		text-align: center;
		line-height: 30px;
		color: #FFF;
		font-size: 14px;
		cursor: pointer;
	}
	.loginBtn.toLCenter{
		/* background-image: none;
		background-color: #fff;
		color: #FF4936; */
	}
	.registerAndForget{
		margin-top: 16px;
	}
	.registerAndForget span{
		font-size: 12px;
	}
	.register a{
		color: #FF4936;
	}
	.register a:hover{
		color: #ea2c18;
	}
	.Forget a{
		float: right;
		color: #333;
	}
	/* 登录后 */
	.studentCon{
		width: 228px;
		margin: 0 auto;
		padding-top: 39px;
		text-align: center;
	}
	.studentImg{
		width: 60px;
		height: 60px;
		border-radius: 30px;
	}
	.studentInfo{
		/* color: #FFF; */
		font-size: 14px;
		margin-top: 22px;
		margin-bottom: 14px;
	}
	.studentName{
		color: #4d6ef2;
		margin-left: 10px;
	}
	.websiteName{
		font-size: 12px;
		/* color: #FFF; */
	}
	.afterLogin .loginBtn{
		margin-top: 23px;
	}
	/* 内容区域 */
	.pageCon{
		width: 1200px;
		margin: 0 auto;
	}
	/* 新闻 */
	.pageOne{
		padding-top: 41px;
	}
	.pageOne::after{
		content: "";
		clear: both;
		display: block;
	}
	.pageOne_left{
		width: 800px;
		height: 430px;
		float: left;
		padding: 23px 19px;
		position: relative;
	}
	.pageOne_right{
		width: 366px;
		height: 430px;
		float: right;
	}
	.pageOneItem{
		background-color: #FFF;
		-moz-box-shadow:1px 1px 6px rgba(153,153,153,0.18);
		-webkit-box-shadow:1px 1px 6px rgba(153,153,153,0.18); 
		box-shadow:1px 1px 6px rgba(153,153,153,0.18); 
	}
	.moreNews{
		position: absolute;
		right: 23px;
		top: 28px;
		font-size: 14px;
		color: #999999;
		cursor: pointer;
	}
	.moreNews:hover{
		color: #2e5cf0;
	}
	.newsItem{
		position: relative;
		padding-left: 312px;
		height: 316px;
		margin-top: 10px;
	}
	.newsItem_left{
		width: 280px;
		position: absolute;
		top: 0;
		left: 0;
		height: 100%;
		background-color: #E4E9FD;
	}
	.noticeOneImg{
		width: 280px;
		height: 176px;
	}
	.noticeOneTitle{
		font-size: 16px;
		color: #333;
		padding: 0 15px;
		line-height: 30px;
		overflow: hidden;
		text-overflow: ellipsis;
		display:-webkit-box; 
		-webkit-box-orient:vertical; 
		-webkit-line-clamp:2; 
	}
	.noticeOneDes{
		font-size: 14px;
		color: #999;
		padding: 0 15px;
		line-height: 24px;
		margin-top: 10px;
	}
	.viewDetails{
		color: #FF4936;
		position: absolute;
		right: 15px;
		bottom: 18px;
		cursor: pointer;
	}
	.viewDetails:hover{
		color: #ea2c18;
	}
	.newsLi{
		position: relative;
		background-image: url(images/newsLi.png);
		background-repeat: no-repeat;
		background-position: center left;
		height: 52px;
		line-height: 52px;
		padding-left: 30px;
		padding-right: 70px;
		overflow:hidden; 
		text-overflow:ellipsis; 
		white-space:nowrap; 
		cursor: pointer;
		font-size: 14px;
		color: #333;
	}
	.newsLi span{
		position: absolute;
		right: 0;
		top: 0;
		color: #999;
	}
	.newsLi:hover{
		color: #FF4936;
	}
	.learningProcess{
		padding: 0 20px;
	}
	.learning_box{
		border-top: 2px solid #FBFBFB;
	}
	.processItem{
		width: 226px;
		height: 40px;
		text-align: center;
		line-height: 40px;
		background-color: #F5F6FE;
		font-size: 16px;
		color: #333;
		border-radius: 2px;
		margin: 31px auto 0 auto;
		position: relative;
		cursor: pointer;
	}
	.processItem:first-child{
		margin-top: 19px;
	}
	.processItem span{
		position: absolute;
		left: 21px;
		font-size: 30px;
		color: #4d6ef2;
		font-style: italic;
		font-weight: bold;
		top: -20px;
	}
	.processItem i{
		color: #4d6ef2;
		position: absolute;
		bottom: -25px;
		left: 105px;
		font-size: 20px;
	}
	/* 精品课程 */
	.excellentCourses .publicTitle{
		margin-top: 13px;
		margin-bottom: 7px;
		position: relative;
	}
	.moreCourses{
		position: absolute;
		right: 0;
		font-size: 0;
		top:0;
		cursor: pointer;
		color: #FF4936;
	}
	.moreCourses span{
		vertical-align: middle;
		font-size: 14px;
	}
	.moreCourses i{
		font-size: 18px;
		font-weight:bold;
		vertical-align: middle;
	}
	.moreCourses:hover{
		color: #ea2c18;
	}
	.specialList::after{
		content: "";
		display: block;
		clear: both;
	}
	.specialItem{
		width: 284px;
		height: 290px;
		float: left;
		margin-right: 20px;
		-moz-box-shadow:-1px -1px 6px 0px rgba(153,153,153,0.18); 
		-webkit-box-shadow:-1px -1px 6px 0px rgba(153,153,153,0.18); 
		box-shadow: -1px -1px 6px 0px rgba(153,153,153,0.18); 
		background-color: #FFF;
		position: relative;
		margin-bottom: 20px;
		border-radius:16px;
		position: relative;
		cursor: pointer;
	}
	.specialItem:last-child{
		margin-right: 0;
	}
	.specialbg{
		width: 284px;
		height: 128px;
		border-radius: 16px 16px 0px 0px;
	}
	.specialItem:nth-child(1) .specialbg{
		background: linear-gradient(72deg,#e5f1f4 0%, #d2dfe2 100%);
	}
	.specialItem:nth-child(2) .specialbg{
		background: linear-gradient(72deg,#e8edfd 0%, #ccd1e0 100%);
	}
	.specialItem:nth-child(3) .specialbg{
		background: linear-gradient(72deg,#eaf1e7 0%, #cfd9cb 100%);
	}
	.specialItem:nth-child(4) .specialbg{
		background: linear-gradient(72deg,#e1dcd5 0%, #cecdca 100%);
	}
	.specialImg{
		width: 244px;
		height: 148px;
		background: #ffffff;
		border-radius: 10px;
		box-shadow: -1.04px 4.89px 18px 5px rgba(51,51,51,0.10);
		position: absolute;
		top: 50px;
		left: 50%;
		margin-left: -122px;
	}
	.specialTit{
		padding: 0 20px;
		font-weight: 400;
		text-align: left;
		color: #000000;
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
		line-height: 1;
		font-size: 18px;
		margin-top: 90px;
	}
	.specialCourses{
		margin-top: 20px;
		padding: 0 20px;
	}
	.specialCourses span{
		line-height: 1;
		font-size: 14px;
		color: #f24736;
		margin-right: 10px;
	}
	/* .coursesList{
		height: 214px;
	} */
	.coursesList::after{
		content: "";
		display: block;
		clear: both;
	}
	.coursesItem{
		width: 225px;
		height: 214px;
		float: left;
		margin-right: 18px;
		-moz-box-shadow:-1px -1px 6px 0px rgba(153,153,153,0.18); 
		-webkit-box-shadow:-1px -1px 6px 0px rgba(153,153,153,0.18); 
		box-shadow: -1px -1px 6px 0px rgba(153,153,153,0.18); 
		background-color: #FFF;
		position: relative;
		transition: margin-top 0.5s;
		margin-bottom: 18px;
	}
	.coursesItem:nth-child(5n+5),
	.coursesItem:last-child{
		margin-right: 0;
	}
	.coursesItem:hover{
		margin-top: -10px;
	}
	.coursesImg{
		width: 225px;
		height: 135px;
		cursor: pointer;
	}
	.coursesTit{
		padding: 0 10px;
		overflow:hidden; 
		text-overflow:ellipsis; 
		white-space:nowrap; 
		font-size: 14px;
		color: #333;
		line-height: 40px;
	}
	.coursesYear{
		width: 137px;
		padding: 0 10px;
		overflow:hidden; 
		text-overflow:ellipsis; 
		white-space:nowrap; 
		font-size: 12px;
		color: #999999;
	}
	.coursesDetails{
		width: 63px;
		height: 24px;
		border: 1px solid #FF4936;
		border-radius: 5px;
		font-size: 12px;
		color: #FF4936;
		text-align: center;
		line-height: 22px;
		position: absolute;
		right: 10px;
		cursor: pointer;
		bottom: 15px;
	}
	.coursesDetails:hover{
		color: #ea2c18;
		border-color: #ea2c18;
	}
	/* 友情链接 */
	.Links{
		margin-top: 13px;
		margin-bottom: 7px;
	}
	.linkList{
		height: 80px;
	}
	.linkList::after{
		content: "";
		display: block;
		clear: both;
	}
	.linkImg_a{
		display: block;
		float: left;
		width: 224px;
		height: 80px;
		margin-right: 20px;
	}
	.linkImg_a:last-child{
		margin-right: 0;
	}
	.linkImg{
		width: 224px;
		height: 80px;
		cursor: pointer;
	}
	.groupRegistration{
		width: 1200px;
		height: 120px;
		margin-top: 41px;
		cursor: pointer;
	}
</style>
<style>
	/* 登录框 */
	.isRemember .el-checkbox__input.is-checked+.el-checkbox__label{
		color: #FF4936;
	}
	.isRemember .el-checkbox__inner{
		background-color: transparent;
	}
	.isRemember .el-checkbox__input.is-checked .el-checkbox__inner, 
	.isRemember .el-checkbox__input.is-indeterminate .el-checkbox__inner{
		background-color: #FF4936;
		border-color: #FF4936;
	}
	.isRemember .el-checkbox__input.is-focus .el-checkbox__inner{
		border-color: #DCDFE6;
	}
	.isRemember .el-checkbox {
		font-size: 12px;
		/* color: #FFF; */
	}
	/* 新闻 */
	.pageOne_left .el-tabs__item{
		font-size: 18px;
		color: #333333;
		line-height: 18px;
	}
	.pageOne_left .el-tabs__item.is-active{
		font-weight: bold;
	}
	.pageOne_left .el-tabs__active-bar{
		background-color:#FF4936;
	}
</style>
